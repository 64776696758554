import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getChargeCodeAPIData } from 'redux/actions/chargeCode/chargeCode.actions';
import { selectors as chargeCodeSelectors } from 'redux/reducers/chargeCode/chargeCode_reducer';
import SearchIcon from "assets/images/search_icon.svg";
import GreenSearchIcon from 'assets/images/greensearch_icon.svg';
import { Loader, Dimmer } from 'semantic-ui-react';
import { Image } from 'semantic-ui-react';
import { ChargeCodeDetails } from 'components/ChargeCodeDetails';
import './SearchableField.scss';

export const SearchableField = ({ fieldId, isValid, validateErrors, onChargeCodeSelected, isSurveyForm, savedValue, isDisable, preferredListHeight, hideSearchIcon }) => {
    const dispatch = useDispatch();
    const popupRef = useRef(null);

    const chargeCodeResponse = useSelector(chargeCodeSelectors?.chargeCodeData);
    const noChargeCodeFound = useSelector(chargeCodeSelectors?.getNoRecordFound);
    const loading = useSelector(chargeCodeSelectors?.getLoading);

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [chargeCodeData, setChargeCodeData] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [dropdownValue, setDropdownValue] = useState('');
    const [showSearchIcon, setShowSearchIconFlag] = useState(true);

    const handleClosePopup = () => {
        setShowPopup(false);
    }

    useEffect(() => {
        if (savedValue) {
            setDropdownValue(savedValue);
            onChargeCodeSelected(savedValue, 'chargeCode');
        }
    }, [savedValue]);

    useEffect(() => {
        if (hideSearchIcon) {
            setShowSearchIconFlag(false);
        }
    }, [hideSearchIcon]);

    useEffect(() => {
        setSearchString('');
    }, [isSurveyForm]);

    useEffect(() => {
        if (chargeCodeResponse?.caseWorkDetails) {
            setChargeCodeData(prevData => [...prevData, ...chargeCodeResponse.caseWorkDetails]);
        }
    }, [chargeCodeResponse]);

    useEffect(() => {
        const handleClickOutside = async (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsPopupOpen(false);
            }
        };
        const handleMouseDown = (event) => {
            // Check if the down arrow image was clicked
            if (!event.target.classList.contains('arrow--img')) {
                handleClickOutside(event);
            }
        };
        document.addEventListener("mousedown", handleMouseDown);
        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, []);


    const handleScroll = useCallback(() => {
        if (popupRef?.current) {
            const { scrollTop, scrollHeight, clientHeight } = popupRef.current;

            if (scrollTop + clientHeight >= scrollHeight - 3 && chargeCodeResponse?.hasNextPage) {
                dispatch(getChargeCodeAPIData(searchString, chargeCodeResponse?.nextPageToken));
            }
        }
    }, [dispatch, chargeCodeResponse]);

    useEffect(() => {
        if (popupRef?.current) {
            popupRef?.current?.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (popupRef?.current) {
                popupRef?.current?.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]);

    const handleChange = async (event) => {
        setIsPopupOpen(true);
        setChargeCodeData([]);
        const regex = /^[0-9.-]*$/;
        if (regex.test(event?.target?.value) || event?.target?.value === '') {
            setSearchString(event?.target?.value);
            await dispatch(getChargeCodeAPIData(event?.target?.value || ''))
        }
    }
    return (
        <>
            <div className="searchable__wrapper">
                <div className='input--outer'>
                    <div className='input__container'>
                        <input
                            type="text"
                            className={dropdownValue ? `input-fields ${!isValid && 'is-fieldInvalid'} hasValue` : `input-fields ${!isValid && 'is-fieldInvalid'}`}
                            style={{height: !showSearchIcon ? '99.5%' : '100%'}}
                            id={fieldId}
                            name={fieldId}
                            value={searchString}
                            disabled={isDisable}
                            onChange={(event) => handleChange(event)}
                            onBlur={(event) => validateErrors(event, fieldId)}
                            onKeyDown={(event) => {
                                if (event.key !== 'Tab') {
                                    setDropdownValue('');
                                    onChargeCodeSelected('', 'chargeCode');
                                }
                            }
                            }
                        />
                        {dropdownValue && <div className="chargecode--badge" onClick={() => setShowPopup(true)}>{dropdownValue} </div>}
                    </div>
                    {!showSearchIcon && <Image src={GreenSearchIcon} alt="search icon" className={`search--button`}    //${expertNameSearchTerm.length < 3 && "disable-icon"}
                        onClick={() => console.log('search clicked')} />}
                </div>
                {
                    loading === true &&
                    <div className='chargecode--loader'>
                        <Dimmer active className="no-background">
                            <Loader className="custom-loader"></Loader>
                        </Dimmer>
                    </div>
                }
                {
                    !loading && showSearchIcon &&
                    <Image alt="searchicon" src={SearchIcon} className='search--icon'></Image>
                }
                {
                    isPopupOpen && (
                        <div className="chargeCode__dropdown" style={{ maxHeight: preferredListHeight }} ref={popupRef}>
                            {
                                noChargeCodeFound === false ? (
                                    chargeCodeData.length > 1 &&
                                        chargeCodeData.every(item => item.closed === true) ? (
                                        <div className="chargeCode__links nomatch--found">All charge codes are closed.</div>
                                    ) : (
                                        chargeCodeData.length === 1 && chargeCodeData[0]?.closed === true ? (
                                            <div className="chargeCode__links nomatch--found">
                                                Charge code entered is closed. <span className='sub--text'>Provide an alternative code or leave blank.</span>
                                            </div>
                                        ) : (
                                            chargeCodeData.map((option, index) => (
                                                !option.closed && (
                                                    <div key={index} className="chargeCode__links" onClick={() => {
                                                        setDropdownValue(option.chargeCode);
                                                        onChargeCodeSelected(option.chargeCode, fieldId);
                                                        setIsPopupOpen(false);
                                                        setSearchString('');
                                                    }}>
                                                        {option.chargeCode}
                                                    </div>
                                                )
                                            ))
                                        )
                                    )
                                ) : (
                                    <div className="chargeCode__links nomatch--found">No matches found. <span className='sub--text'>Provide an alternative code or leave blank.</span></div>
                                )
                            }
                        </div>
                    )
                }
            </div>
            {
                showPopup && <ChargeCodeDetails chargeCode={dropdownValue} showPopup={showPopup} handleClosePopup={handleClosePopup} />
            }
        </>
    )
}